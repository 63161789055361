<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="存货单详情"
    :visible.sync="showDialog"
    top="5vh"
    width="1160px"
    @close="close"
  >
    <div v-loading="loading" class="wrapper">
      <el-alert
        :closable="false"
        :title="titleTip"
        type="success"
        style="width: 86%; margin-top: -70px; margin-left: 100px"
      ></el-alert>
      <div class="orderStatus">{{ orderState }}</div>

      <el-form :model="data" inline label-width="90px">
        <el-form-item label="客户名称" prop="cust_name">
          <el-input v-model="data.cust_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contact">
          <el-input v-model.trim="data.contact" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address" class="dz">
          <el-input
            v-model.trim="data.address"
            disabled
            style="width: 380px"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model="data.info_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="业务员">
          <el-input v-model="data.user_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="签单金额">
          <el-input v-model="data.total_amount" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="已收款">
          <el-input v-model="data.receive_amount" disabled></el-input>
        </el-form-item>

        <el-form-item label="待收款">
          <el-input v-model="data.arrears_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="data.remark"
            disabled
            style="width: 500px"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="table">
        <div class="tit">存货详情</div>
        <base-table
          :list.sync="inventoryData"
          :columns.sync="inventoryColumns"
          :show-summary="true"
          :is-border="true"
          :summary="inventorySummary"
        ></base-table>
        <div v-if="type == 2" style="margin: 20px">
          <span>协议赠品限制:</span>
          <el-radio v-model="data.gift_type" :label="1">无限制</el-radio>
          <el-radio v-model="data.gift_type" :label="2">
            付款完成可下赠品
          </el-radio>
          <el-radio v-model="data.gift_type" :label="3">
            全部换货后可下赠品
          </el-radio>
        </div>
        <div class="tit">余货一览</div>
        <base-table
          :list.sync="surplusData"
          :columns.sync="surplusColumns"
          :show-summary="false"
          :is-border="true"
          :summary="surplusSummary"
        ></base-table>
        <div class="tit">还货记录</div>
        <base-table
          :list.sync="returnData"
          :columns.sync="returnColumns"
          :show-summary="false"
          :is-border="true"
          :summary="returnSummary"
          :is-merge="false"
        ></base-table>
        <div class="tit">收款记录</div>
        <base-table
          :list.sync="backData"
          :columns.sync="backColumns"
          :show-summary="true"
          :is-border="true"
          :summary="backSummary"
        ></base-table>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="type == 1" @click="handlePrint">打印</el-button>
      <el-button v-if="type == 2" type="primary" @click="handleSubmit">
        保存
      </el-button>
      <el-button @click="close">关闭</el-button>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="YH"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </el-dialog>
</template>
<script>
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'

  import { clientRowDetail } from '@/api/createPreInventory'
  import BaseTable from '@/baseComponents/baseTable'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Check',
    components: {
      BaseTable,
      RuilangDisplayDialog,
    },
    data() {
      return {
        type: 1, //q查看
        data_id: null,
        orderState: '状态',
        loading: true,
        showDialog: false,
        id: 0,
        data: {
          gift_type: 1,
        },
        // 存货详情
        inventoryData: [],
        inventoryColumns: [
          {
            width: '110',
            label: '类型',
            prop: 'type_text',
          },
          {
            width: '210',
            label: '商品名称',
            prop: 'goods_name_print',
          },
          {
            width: '110',
            label: '规格',
            prop: 'goods_specs',
          },

          {
            width: '100',
            label: '单位',
            prop: 'unit_name',
          },
          {
            width: '110',
            label: '存货价',
            prop: 'goods_price',
          },
          {
            width: '140',
            label: '存货数量',
            prop: 'goods_num',
          },
          {
            width: '140',
            label: '金额',
            prop: 'goods_money',
          },
          {
            width: 'auto',
            label: '备注',
            prop: 'goods_remark',
          },
        ],
        inventorySummary: [],
        // 余货一览
        surplusData: [],
        surplusColumns: [
          {
            width: '',
            label: '类型',
            prop: 'type_text',
          },
          {
            width: '210',
            label: '商品名称',
            prop: 'goods_name_print',
          },
          {
            width: '',
            label: '规格',
            prop: 'goods_specs',
          },

          {
            width: '',
            label: '存货数量',
            prop: 'goods_num',
          },
          {
            width: '',
            label: '余货数量',
            prop: 'goods_left_num',
          },
        ],
        surplusSummary: [],
        // 还货记录
        returnData: [],
        returnColumns: [
          {
            label: '单号/时间',
            prop: 'bill_code',
            width: '210',
          },
          {
            label: '商品',
            prop: 'goods_name_print',
            width: '180',
          },
          {
            label: '规格',
            prop: 'goods_specs',
            width: '',
          },
          {
            label: '还货数量',
            prop: 'return_num',
            width: '',
          },
          // {
          //   label: '余货数量',
          //   prop: 'left_num',
          //   width: '',
          // },
        ],
        returnSummary: [],
        // 收款记录
        backData: [],
        backColumns: [
          {
            width: '210',
            prop: 'create_at',
            label: '时间',
          },
          {
            width: '210',
            prop: 'pay_type',
            label: '收款方式',
          },

          {
            width: '210',
            prop: 'amount',
            label: '金额',
          },
          {
            width: '210',
            prop: 'user_name',
            label: '收款人',
          },
        ],
        backSummary: [],
      }
    },
    computed: {
      titleTip() {
        return '单号:' + this.data.bill_code + ' 签单:' + this.data.create_at
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        } else {
          this.type = 1
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await clientRowDetail({ id: this.id })
        if (code == 200) {
          console.log(data)
          this.data = data
          let a = ''
          if (data.status == 0) {
            a = '未审核'
          } else if (data.status == 1) {
            a = '已审核'
          } else if (data.status == 2) {
            a = '已终止'
          } else if (data.status == 3) {
            a = '已完成'
          } else if (data.status == 4) {
            a = '已作废'
          }
          this.orderState = a

          // 库存表格数据
          this.inventoryData = this.data.summary_log.summary_list
          this.inventorySummary = [
            { idx: 0, info: '合计' },
            {
              idx: 5,
              info: this.data.summary_log.summary_total_unit,
            },
            {
              idx: 6,
              info: this.data.summary_log.summary_total_amount,
            },
          ]
          // 还货记录
          this.returnData = this.data.return_log.returen_list
          // 余货一览
          this.surplusData = this.data.left_log.left_list
          this.surplusSummary = [
            {
              idx: 0,
              info: '合计',
            },
            {
              idx: 3,
              info: this.data.left_log.summary_total_unit,
            },
            {
              idx: 4,
              info: this.data.left_log.summary_left_unit,
            },
          ]
          // 收款记录
          this.backData = this.data.collection_log.collection_list
          this.backSummary = [
            { idx: 0, info: '合计' },
            {
              idx: 2,
              info: this.data.collection_log.collection_total_amount,
            },
          ]
        }
        this.loading = false
      },

      close() {
        this.showDialog = false
      },
      handlePrint() {
        this.data_id = this.id
        this.$refs['RuilangDisplayDialog'].batch = false
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
      handleSubmit() {
        postAction('/promoteAdmin/goods-order/order-update', {
          order_id: this.id,
          gift_type: this.data.gift_type,
        }).then((r) => {
          this.$message.success(r.msg)
          this.$emit('refresh')
          this.showDialog = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .tit {
    line-height: 30px;
  }
</style>
