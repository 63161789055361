<template>
  <div class="wrapper">
    <el-table
      stripe
      :data="list"
      :border="isBorder"
      :summary-method="getSummaries"
      :show-summary="showSummary"
      :span-method="cellMerge"
    >
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :prop="item.prop"
        :width="item.width"
        :label="item.label"
        align="center"
      >
        <template #default="{ row }">
          <div v-if="item.label == '单号/时间'">
            {{ row.bill_code }}
            <br />
            {{ row.create_at }}
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
  export default {
    name: 'BaseTable',
    components: {},
    props: {
      list: {
        type: Array,
        default: () => [],
      },
      showSummary: {
        type: Boolean,
        default: () => false,
      },
      isBorder: {
        type: Boolean,
        default: () => false,
      },
      columns: {
        type: Array,
        default: () => [],
      },
      summary: {
        type: Array,
        default: () => {
          return [{ idx: 0, info: '合计' }]
        },
      },
      isMerge: {
        type: Boolean,
        default: () => false,
      },
    },
    data() {
      return {
        spanArr: [],
        pos: 0,
      }
    },
    computed: {},
    watch: {},
    created() {
      if (this.isMerge) {
        this.getSpanArr(this.list)
      }
    },
    mounted() {},
    methods: {
      // 处理合并表格数据
      getSpanArr(data) {
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i].bill_code === data[i - 1].bill_code) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },

      // 合并列
      cellMerge({ row, column, rowIndex, columnIndex }) {
        if (this.isMerge) {
          if (columnIndex === 0) {
            let _row = this.spanArr[rowIndex]
            let _col = _row > 0 ? 1 : 0
            return {
              rowspan: _row,
              colspan: _col,
            }
          }
        }
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          this.summary.forEach((item) => {
            if (index === item.idx) {
              sums[index] = item.info
              return
            }
          })
        })

        return sums
      },
    },
  }
</script>
<style lang="scss" scoped></style>
