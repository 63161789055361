var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        "close-on-click-modal": false,
        title: "存货单详情",
        visible: _vm.showDialog,
        top: "5vh",
        width: "1160px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrapper",
        },
        [
          _c("el-alert", {
            staticStyle: {
              width: "86%",
              "margin-top": "-70px",
              "margin-left": "100px",
            },
            attrs: { closable: false, title: _vm.titleTip, type: "success" },
          }),
          _c("div", { staticClass: "orderStatus" }, [
            _vm._v(_vm._s(_vm.orderState)),
          ]),
          _c(
            "el-form",
            { attrs: { model: _vm.data, inline: "", "label-width": "90px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称", prop: "cust_name" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.cust_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "cust_name", $$v)
                      },
                      expression: "data.cust_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "contact" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.contact,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.data,
                          "contact",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.contact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "dz",
                  attrs: { label: "地址", prop: "address" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "380px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.data,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "data.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "活动名称" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.info_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "info_name", $$v)
                      },
                      expression: "data.info_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "业务员" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.user_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "user_name", $$v)
                      },
                      expression: "data.user_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "签单金额" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.total_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "total_amount", $$v)
                      },
                      expression: "data.total_amount",
                    },
                  }),
                ],
                1
              ),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "已收款" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.receive_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "receive_amount", $$v)
                      },
                      expression: "data.receive_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "待收款" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.arrears_amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "arrears_amount", $$v)
                      },
                      expression: "data.arrears_amount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.data.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "remark", $$v)
                      },
                      expression: "data.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table" },
            [
              _c("div", { staticClass: "tit" }, [_vm._v("存货详情")]),
              _c("base-table", {
                attrs: {
                  list: _vm.inventoryData,
                  columns: _vm.inventoryColumns,
                  "show-summary": true,
                  "is-border": true,
                  summary: _vm.inventorySummary,
                },
                on: {
                  "update:list": function ($event) {
                    _vm.inventoryData = $event
                  },
                  "update:columns": function ($event) {
                    _vm.inventoryColumns = $event
                  },
                },
              }),
              _vm.type == 2
                ? _c(
                    "div",
                    { staticStyle: { margin: "20px" } },
                    [
                      _c("span", [_vm._v("协议赠品限制:")]),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.data.gift_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "gift_type", $$v)
                            },
                            expression: "data.gift_type",
                          },
                        },
                        [_vm._v("无限制")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.data.gift_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "gift_type", $$v)
                            },
                            expression: "data.gift_type",
                          },
                        },
                        [_vm._v(" 付款完成可下赠品 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.data.gift_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "gift_type", $$v)
                            },
                            expression: "data.gift_type",
                          },
                        },
                        [_vm._v(" 全部换货后可下赠品 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "tit" }, [_vm._v("余货一览")]),
              _c("base-table", {
                attrs: {
                  list: _vm.surplusData,
                  columns: _vm.surplusColumns,
                  "show-summary": false,
                  "is-border": true,
                  summary: _vm.surplusSummary,
                },
                on: {
                  "update:list": function ($event) {
                    _vm.surplusData = $event
                  },
                  "update:columns": function ($event) {
                    _vm.surplusColumns = $event
                  },
                },
              }),
              _c("div", { staticClass: "tit" }, [_vm._v("还货记录")]),
              _c("base-table", {
                attrs: {
                  list: _vm.returnData,
                  columns: _vm.returnColumns,
                  "show-summary": false,
                  "is-border": true,
                  summary: _vm.returnSummary,
                  "is-merge": false,
                },
                on: {
                  "update:list": function ($event) {
                    _vm.returnData = $event
                  },
                  "update:columns": function ($event) {
                    _vm.returnColumns = $event
                  },
                },
              }),
              _c("div", { staticClass: "tit" }, [_vm._v("收款记录")]),
              _c("base-table", {
                attrs: {
                  list: _vm.backData,
                  columns: _vm.backColumns,
                  "show-summary": true,
                  "is-border": true,
                  summary: _vm.backSummary,
                },
                on: {
                  "update:list": function ($event) {
                    _vm.backData = $event
                  },
                  "update:columns": function ($event) {
                    _vm.backColumns = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.type == 1
            ? _c("el-button", { on: { click: _vm.handlePrint } }, [
                _vm._v("打印"),
              ])
            : _vm._e(),
          _vm.type == 2
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "YH", data_id: _vm.data_id, type: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }