var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "el-table",
        {
          attrs: {
            stripe: "",
            data: _vm.list,
            border: _vm.isBorder,
            "summary-method": _vm.getSummaries,
            "show-summary": _vm.showSummary,
            "span-method": _vm.cellMerge,
          },
        },
        _vm._l(_vm.columns, function (item, index) {
          return _c("el-table-column", {
            key: index,
            attrs: {
              prop: item.prop,
              width: item.width,
              label: item.label,
              align: "center",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      item.label == "单号/时间"
                        ? _c("div", [
                            _vm._v(" " + _vm._s(row.bill_code) + " "),
                            _c("br"),
                            _vm._v(" " + _vm._s(row.create_at) + " "),
                          ])
                        : _c("div", [
                            _vm._v(" " + _vm._s(row[item.prop]) + " "),
                          ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }